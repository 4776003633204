"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 0,
  // request timeout
  headers: {
    'content-type': 'application/json;charset=UTF-8'
  }
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  // if (store.getters.token) {
  // let each request carry token
  // ['X-Token'] is a custom headers key
  // please modify it according to the actual situation
  // }
  config.headers['token'] = (0, _auth.getToken)();
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // 200 成功，11无更改
  if (![0, 200, 11].includes(res.code)) {
    (0, _elementUi.Message)({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    if ([401, 402].includes(res.code)) {
      _store.default.dispatch('user/logout').then(function () {
        location.reload();
      });
    }

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      _elementUi.MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  (0, _elementUi.Message)({
    message: error.msg || 'Error',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;