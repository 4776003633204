var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".login-container{min-height:100%;width:100%;overflow:hidden;background:url(" + escape(require("../../assets/login-bg.jpg")) + ") no-repeat center center/cover}.login-container .login-form{position:relative;width:520px;max-width:100%;margin:0 auto;overflow:hidden;border-radius:5px;margin-top:200px;padding:35px;background:rgba(255,255,255,0.9)}.login-container .login-form .title-container{position:relative}.login-container .login-form .title-container .title{font-size:26px;color:#889aa4;margin:0px auto 40px auto;text-align:center;font-weight:bold}.login-container .login-form .svg-container{padding:6px 5px 6px 15px;color:#889aa4;vertical-align:middle;width:30px;display:inline-block}.login-container .login-form .show-pwd{position:absolute;right:10px;top:7px;font-size:16px;color:#889aa4;cursor:pointer;user-select:none}.login-container .login-form .forgot-pwd{margin:-10px 0 22px 0;font-size:12px;text-align:right}.login-container .login-form .forgot-pwd .text{cursor:pointer;color:#666}.login-container .login-form .forgot-pwd .text:hover{color:#1890ff}.login-container .login-form .submit{width:100%;padding:15px;margin-bottom:20px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px"
};