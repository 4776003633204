"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _default = exports.default = {
  data: function data() {
    return {
      redirect: null,
      otherQuery: {}
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  methods: {
    jumpPage: function jumpPage() {
      this.$router.push({
        path: '/config',
        query: this.otherQuery
      });
      // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    showPwd: function showPwd(field) {
      this[field] = !this[field];
    }
  }
};