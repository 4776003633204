"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.loadView = void 0;
require("core-js/modules/es6.array.find");
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router2 = require("@/router");
var _index = _interopRequireDefault(require("@/layout/index"));
var _common = _interopRequireDefault(require("@services/common"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var _router = {
  msg: '操作成功',
  code: 200,
  data: [{
    path: '/activity_manage',
    component: 'Layout',
    key: '/activity_manage',
    meta: {
      title: '全局活动管理',
      icon: 'list'
    },
    children: [{
      name: 'config',
      path: '/config',
      component: 'activity-global/config',
      key: 'manageConfig',
      meta: {
        title: '红包活动管理'
      }
    }, {
      name: 'managePrize',
      path: '/prize',
      component: 'activity-global/prize',
      key: 'managePrize',
      meta: {
        title: '红包奖品管理'
      }
    }, {
      name: 'manageQuery',
      path: '/query',
      component: 'activity-global/query',
      key: 'manageQuery',
      meta: {
        title: '活动数据查询'
      }
    }, {
      name: 'manageRecord',
      path: '/record',
      component: 'activity-global/record',
      key: 'manageRecord',
      meta: {
        title: '用户数据查询'
      }
    }, {
      name: 'manageAddNum',
      path: '/add_num',
      component: 'activity-global/add-num',
      key: 'manageAddNum',
      meta: {
        title: '额外次数管理'
      }
    }, {
      name: 'manageErrorPage',
      path: '/error-page',
      component: 'activity-global/error-page',
      key: 'manageErrorPage',
      meta: {
        title: '错误'
      }
    }]
  }, {
    path: '/activity_frenzy',
    component: 'Layout',
    key: '/activity_frenzy',
    meta: {
      title: '红包狂潮',
      icon: 'list'
    },
    children: [{
      name: 'manageActivity',
      path: '/activity',
      component: 'activity-frenzy/activity',
      key: 'manageActivity',
      meta: {
        title: '活动管理'
      }
    }, {
      name: 'manageDepositHistory',
      path: '/depositHistory',
      component: 'activity-frenzy/depositHistory',
      key: 'manageDepositHistory',
      meta: {
        title: '存款记录'
      }
    }, {
      name: 'manageBetHistory',
      path: '/betHistory',
      component: 'activity-frenzy/betHistory',
      key: 'manageBetHistory',
      meta: {
        title: '投注历史'
      }
    }, {
      name: 'manageConfig',
      path: '/manageConfig',
      component: 'activity-frenzy/manageConfig',
      key: 'manageConfig',
      meta: {
        title: '活动条件'
      }
    }]
  }]
};
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router2.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      // 向后端请求路由数据
      _common.default.getMenu().then(function (res) {
        var code = res.code,
          data = res.data;
        if (code == 0 && data && data.length) {
          var menu = res.data[0];
          var routerArr = _router.data;
          var newMenu = handleMenu(routerArr, menu);
          var accessedRoutes = filterAsyncRouter(newMenu);
          accessedRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          console.log(accessedRoutes, 'accessedRoutes');
          commit('SET_ROUTES', accessedRoutes);
          resolve(accessedRoutes);
        }
      });
    });
  }
};
function handleMenu(router, menu) {
  var res = [];
  var obj = {};
  router.forEach(function (item) {
    if (hasPermission(menu, item)) {
      console.log(menu, item, 'menu');
      var parent = menu.find(function (ele) {
        return ele.url === item.key;
      });
      console.log(parent, 'parent');
      if (parent) {
        obj = _objectSpread({}, item);
        if (parent.child) {
          obj.children = [];
          parent.child.forEach(function (child) {
            item.children.forEach(function (children) {
              if (child.url == children.key) {
                obj.children.push(children);
              }
            });
          });
          console.log(obj.children, 'obj.children');
          // obj.children = handleMenu(parent.children, menu)
        }
        res.push(obj);
      }
    }
  });
  return res;
}
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else {
        console.log();
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
var loadView = exports.loadView = function loadView(view) {
  // 路由懒加载
  // return () => import(`@/views/${view}`)
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};