"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _loginMixin = _interopRequireDefault(require("../login-mixin.js"));
var _auth = require("@/utils/auth");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  mixins: [_loginMixin.default],
  data: function data() {
    return {
      loginForm: {
        name: '',
        password: ''
      },
      showPassword: false,
      capsTooltip: false,
      loading: false,
      loginRules: {
        name: [{
          required: true,
          trigger: 'blur',
          message: '用户名不能为空'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }]
      },
      qrcode: null,
      code: undefined,
      secret: undefined,
      codeNum: 1,
      codeVisible: false
    };
  },
  methods: {
    handleLogin: function handleLogin() {
      var _this = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var obj = _objectSpread({}, _this.loginForm);
          if (_this.code) {
            obj.code = _this.code;
            obj.secret = _this.secret;
            delete obj.captcha;
          }
          if (obj.code === '') {
            delete obj.code;
          }
          _this.$store.dispatch('user/login', obj).then(function (response) {
            _this.loading = false;
            var code = response.code,
              message = response.message,
              msg = response.msg,
              data = response.data;
            console.log(response, code, 'response');
            if (code === 407) {
              _this.qrcode = data.qr_code;
              _this.secret = data.secret;
              _this.codeVisible = true;
              return;
            }
            if (code === 402) {
              if (_this.codeNum === 1) {
                _this.code = '';
                _this.codeVisible = true;
                _this.codeNum = 2;
                return;
              }
              if (_this.codeNum === 2) {
                _this.$message.error(msg);
                return;
              }
            }
            if (code == 0) {
              var token = data.access_token;
              localStorage.setItem('token', token);
              (0, _auth.setToken)(token);
              _this.jumpPage();
            }
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    handleClose: function handleClose() {
      this.codeNum = 1;
      this.code = '';
      this.qrcode = '';
      this.codeVisible = false;
    }
  }
};