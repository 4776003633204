"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _app = _interopRequireDefault(require("./modules/app"));
var _user = _interopRequireDefault(require("./modules/user"));
var _tagsView = _interopRequireDefault(require("./modules/tagsView"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _errorLog = _interopRequireDefault(require("./modules/errorLog"));
var _getters = _interopRequireDefault(require("./getters"));
_vue.default.use(_vuex.default);

// // https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', true, /\.js$/)

// // you do not need `import app from './modules/app'`
// // it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

var store = new _vuex.default.Store({
  state: {
    stateList: []
  },
  modules: {
    app: _app.default,
    user: _user.default,
    errorLog: _errorLog.default,
    tagsView: _tagsView.default,
    permission: _permission.default,
    settings: _settings.default
  },
  getters: _getters.default
});
var _default = exports.default = store;