"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/activity_manage'
}
// 只加登录验证
// {
//   path: '/activity',
//   component: Layout,
//   redirect: '/activity/config',
//   meta: { title: '520活动管理', icon: 'list', noCache: true },
//   children: [
//     {
//       path: 'config',
//       name: 'config',
//       component: () => import('@/views/activity/config/index'),
//       meta: { title: '活动配置', noCache: true }
//     },
//     {
//       path: 'prize',
//       name: 'prize',
//       component: () => import('@/views/activity/prize/index'),
//       meta: { title: '奖品管理', noCache: true }
//     },
//     {
//       path: 'packet',
//       name: 'packet',
//       component: () => import('@/views/activity/packet'),
//       meta: { title: '红包活动管理', noCache: true }
//     },
//     {
//       path: 'record1',
//       name: 'record1',
//       component: () => import('@/views/activity/record/index'),
//       meta: { title: '第一场红包雨纪录', noCache: true },
//       props: {
//         type: 1
//       }
//     },
//     {
//       path: 'record2',
//       name: 'record2',
//       component: () => import('@/views/activity/record/index'),
//       meta: { title: '第二场红包雨纪录', noCache: true },
//       props: {
//         type: 2
//       }
//     },
//     {
//       path: 'record3',
//       name: 'record3',
//       component: () => import('@/views/activity/record/index'),
//       meta: { title: '第三场红包雨纪录', noCache: true },
//       props: {
//         type: 3
//       }
//     },
//   ]
// },
// {
//   path: '/activity_manage',
//   component: Layout,
//   redirect: '/activity_manage/config',
//   meta: { title: '全局活动管理', icon: 'list', noCache: true },
//   children: [
//     {
//       path: 'config',
//       name: 'manageConfig',
//       component: () => import('@/views/activity-global/config/index'),
//       meta: { title: '红包活动管理', noCache: true }
//     },
//     {
//       path: 'prize',
//       name: 'managePrize',
//       component: () => import('@/views/activity-global/prize/index'),
//       meta: { title: '红包奖品管理', noCache: true }
//     },
//     {
//       path: 'query',
//       name: 'manageQuery',
//       component: () => import('@/views/activity-global/query'),
//       meta: { title: '活动数据查询', noCache: true }
//     },
//     {
//       path: 'record',
//       name: 'manageRecord',
//       component: () => import('@/views/activity-global/record'),
//       meta: { title: '用户数据查询', noCache: true }
//     },
//     {
//       path: 'add_num',
//       name: 'addNum',
//       component: () => import('@/views/activity-global/add-num'),
//       meta: { title: '额外次数管理', noCache: true }
//     },

//   ]
// },
// {
//   path: '/activity_frenzy',
//   component: Layout,
//   redirect: '/activity_frenzy/time',
//   meta: { title: '红包狂潮', icon: 'list', noCache: true },
//   children: [
//     {
//       path: 'activity',
//       name: 'manageActivity',
//       component: () => import('@/views/activity-frenzy/activity/index'),
//       meta: { title: '活动管理', noCache: true }
//     },
//     {
//       path: 'depositHistory',
//       name: 'manageDepositHistory',
//       component: () => import('@/views/activity-frenzy/depositHistory/index'),
//       meta: { title: '存款记录', noCache: true }
//     },
//     {
//       path: 'betHistory',
//       name: 'manageBetHistory',
//       component: () => import('@/views/activity-frenzy/betHistory/index'),
//       meta: { title: '投注历史', noCache: true }
//     },
//     {
//       path: 'config',
//       name: 'manageConfig',
//       component: () => import('@/views/activity-frenzy/config/index'),
//       meta: { title: '活动条件', noCache: true }
//     },
//     // {
//     //   path: 'prize',
//     //   name: 'managePrize',
//     //   component: () => import('@/views/activity-frenzy/prize/index'),
//     //   meta: { title: '活动管理', noCache: true }
//     // },

//   ]
// },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    base: process.env.NODE_ENV !== 'development' ? '/red/' : '/',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;