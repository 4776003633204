"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/build-app/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/build-app/node_modules/core-js/modules/es6.array.iterator.js");
require("/build-app/node_modules/core-js/modules/es6.promise.js");
require("/build-app/node_modules/core-js/modules/es6.object.assign.js");
require("/build-app/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _downloadPdf = _interopRequireDefault(require("./utils/download-pdf"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// a modern alternative to CSS resets

// global css

// icon
// permission control
// error log

// global filters

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'development') {
//   mockXHR()
// }

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});
_vue.default.use(_downloadPdf.default);

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});