"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _jspdf = _interopRequireDefault(require("jspdf"));
// 导出页面为PDF格式

window.html2canvas = _html2canvas.default;
var _default = exports.default = {
  install: function install(Vue) {
    Vue.prototype.getPdf = function () {
      var title = this.htmlTitle;
      var PDF = new _jspdf.default('', 'pt', 'a4');
      var el = document.querySelector('#downloadPdf');
      var w = 600;
      var h = 820;
      var scale = (window.devicePixelRatio || 1) * 2;
      (0, _html2canvas.default)(el, {
        // allowTaint: true,
        useCORS: true,
        scale: scale,
        logging: true,
        width: el.offsetWidth,
        height: el.offsetHeight
      }).then(function (obj) {
        var aw = obj.width;
        var ah = aw * h / w;
        console.log('实际宽度:' + aw + ' 等比缩放后单页实际高度:' + ah);
        var docs = document.getElementsByClassName('pdf-model');
        var iop = 0;
        var ip = 0;
        Array.prototype.forEach.call(docs, function (ele) {
          if (ip + ele.offsetHeight * scale - iop > ah) {
            console.log('当前块高度：' + ele.offsetHeight * scale, '上个页面高度:' + iop, '当前页面高度:' + (ip - iop));
            var canvas = cparea(obj, 0, iop, aw, ip - iop);
            var pageData = canvas.toDataURL('image/jpeg', 1.0);
            PDF.addImage(pageData, 'JPEG', 0, 0, w, h);
            PDF.addPage();
            iop = ip;
          }
          ip += ele.offsetHeight * scale;
          console.log('当前高度:' + ip);
        });
        if (ip - iop > 0) {
          var canvas = cparea(obj, 0, iop, aw, ip - iop);
          var pageData = canvas.toDataURL('image/jpeg', 1.0);
          PDF.addImage(pageData, 'JPEG', 0, 0, w, h);
        }
        PDF.save("".concat(title, ".pdf"));
      });
      function cparea(obj, x, y, iw, ih) {
        var canvas = document.createElement('canvas');
        canvas.width = w * scale;
        canvas.height = h * scale;
        canvas.style.width = w + 'px';
        canvas.style.height = h + 'px';
        var ctx = canvas.getContext('2d');
        ctx.scale(scale, scale);
        ctx.imageSmoothingEnabled = true;
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(obj, x, y, iw, ih, 0, 0, w, w / iw * ih);
        return canvas;
      }
    };
  }
};