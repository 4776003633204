"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addConfig = addConfig;
exports.addPrize = addPrize;
exports.delConfig = delConfig;
exports.delPrize = delPrize;
exports.editConfig = editConfig;
exports.editPrize = editPrize;
exports.getConfigList = getConfigList;
exports.getPrizeList = getPrizeList;
exports.getRecordList = getRecordList;
exports.getUserRedInfo = getUserRedInfo;
exports.login = login;
exports.logout = logout;
exports.redListExport = redListExport;
exports.updateReissue = updateReissue;
var _request = _interopRequireDefault(require("@/utils/request"));
var _index = require("@/utils/index");
function login(data) {
  return (0, _request.default)({
    url: process.env.VUE_APP_BASE_API_AUTH + '/oauth/login',
    method: 'post',
    data: data
  });
}
function logout(data) {
  return (0, _request.default)({
    url: '/gred/api/logout',
    method: 'post',
    data: data
  });
}

// 活动配置
function getConfigList(data) {
  return (0, _request.default)({
    url: '/red/act/list',
    method: 'get',
    params: (0, _index.clearNullField)(data)
  });
}
function addConfig(data) {
  return (0, _request.default)({
    url: '/red/act/add',
    method: 'post',
    data: data
  });
}
function delConfig(data) {
  return (0, _request.default)({
    url: '/red/act/delete',
    method: 'post',
    data: data
  });
}
function editConfig(data) {
  return (0, _request.default)({
    url: '/red/act/modify',
    method: 'post',
    data: data
  });
}

// 奖品管理
function getPrizeList(data) {
  return (0, _request.default)({
    url: '/red/award/list',
    method: 'get',
    params: (0, _index.clearNullField)(data)
  });
}
function addPrize(data) {
  return (0, _request.default)({
    url: '/red/award/add',
    method: 'post',
    data: data
  });
}
function delPrize(data) {
  return (0, _request.default)({
    url: '/red/award/delete',
    method: 'post',
    data: data
  });
}
function editPrize(data) {
  return (0, _request.default)({
    url: '/red/award/modify',
    method: 'post',
    data: data
  });
}

// 红包记录
function getRecordList(data) {
  return (0, _request.default)({
    url: '/red/record/list',
    method: 'post',
    data: (0, _index.clearNullField)(data)
  });
}

// 用户活动信息查询
function getUserRedInfo(data) {
  return (0, _request.default)({
    url: '/red/record/playerInfo',
    method: 'post',
    data: data
  });
}

// 补发
function updateReissue(data) {
  return (0, _request.default)({
    url: '/red/player/addBonus',
    method: 'post',
    data: data
  });
}

// 导出
function redListExport(data) {
  return (0, _request.default)({
    url: '/red/record/export',
    method: 'get',
    params: (0, _index.clearNullField)(data)
  });
}